import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Right arrow icon

const BackToStore = ({ shopUrl, buttonText }) => {
  const handleClick = () => {
    window.location.href = shopUrl; // Redirects to the specified URL
  };

  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  };

  return (
    <div style={buttonStyle}>
      <button 
        onClick={handleClick}
        className="bg-transparent hover:bg-[rgba(197,133,107,1)] py-4 w-full border border-[rgba(197,133,107,1)] rounded-[23px] text-black hover:text-white mt-4 transition duration-300 ease-in-out flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faArrowRight} /> {/* Arrow first */}
        <span className="mr-2">{buttonText}</span> {/* Text with margin to the left */}
      </button>
    </div>
  );
};

export default BackToStore;
