const config = {
  shopUrl: "https://merlaux.com",
  language: "he",
  isRTL: true,
};

export default config;

export const translations = {
  en: {
    title: "Track Your Order Now",
    subtitle: "Enter Your Tracking ID or Reference Number",
    orderIdPlaceholder: "Enter Order ID",
    trackNowButton: "Track Now",
    loading: "Loading...",
    orderShippedMessage: "Your order shipped successfully.",
    invalidOrderId: "Invalid Order ID. It must be a number with at least 5 digits. Example: 12345",
    orderNotFound: "Order not found",
    orderProcessing: "Your order is still processing. No tracking information available yet.",
    itemsStillProcessing: "Items Still Processing",
    processingMessage: "These items are currently being processed. We'll update you when they're shipped.",
    quantity: "Quantity",
    hideItems: "Hide Items",
    viewItems: "View Items",
    itemsInGroup: "Items in this group:",
    trackingInfo: "Tracking Information",
    noTrackingInfo: "No tracking information available.",
    currentStatus: "Current Status:",
    lastUpdated: "Last Updated:",
    estimatedDelivery: "Estimated Delivery:",
    estimatedDeliveryMessage: "Estimated delivery time: 4-6 business days from the fulfillment date.",
    availableForPickup: "Available for Pickup",
    availableForPickupMessage: "Your package is available for pickup. Please collect it from the designated location.",
    customsIssue: "Customs Issue",
    customsIssueMessage: "Your package is held at customs. Please contact support for further assistance.",
    deliveryIssue: "Delivery Issue",
    deliveryIssueMessage: "There was an issue with the delivery. Please contact customer support for assistance.",
    unknownStatus: "Unknown Status",
    unknownStatusMessage:
      "We're unable to determine the current status. Please contact customer support for more information.",
    of: "of",
    itemsShipped: "items shipped",
    items: { title: "Items" },
    order: { title: "Order" },
    orderStatus: {
      fulfilled: "Fulfilled",
      partiallyFulfilled: "Partially Fulfilled",
    },
    trackingNumber: "Tracking Number:",
    trackingStages: {
      processing: "Processing",
      packaging: "Packaging",
      inTransit: "In Transit",
      outForDelivery: "Out for Delivery",
      delivered: "Delivered",
    },
    deliveredMessage: "Your order has been delivered.",
    expectedArrivalMessage: "Your order is expected to arrive by",
    estimatedDeliveryMessageWithDate:
      "Your package is estimated to arrive by {date} (4-6 business days from fulfillment).",
    orderDontexist: "Order does not exist.",
    orderId: "Order Id",
    trackingId: "Tracking Id",
    backToStore: "Back to store",
  },
  he: {
    title: "עקוב אחר ההזמנה שלך עכשיו",
    subtitle: "הזן את מספר המעקב או מספר ההפניה שלך",
    orderIdPlaceholder: "הזן מזהה הזמנה",
    trackNowButton: "עקוב עכשיו",
    loading: "טוען...",
    orderShippedMessage: "ההזמנה שלך נשלחה בהצלחה.",
    invalidOrderId: "מזהה הזמנה לא חוקי. חייב להיות מספר עם לפחות 5 ספרות. דוגמה: 12345",
    orderNotFound: "הזמנה לא נמצאה",
    orderProcessing: "ההזמנה שלך עדיין בעיבוד. אין מידע מעקב זמין עדיין.",
    itemsStillProcessing: "פריטים עדיין בעיבוד",
    processingMessage: "פריטים אלה נמצאים כרגע בעיבוד. נעדכן אותך כשהם יישלחו.",
    quantity: "כמות",
    hideItems: "הסתר פריטים",
    viewItems: "הצג פריטים",
    itemsInGroup: "פריטים בקבוצה זו:",
    trackingInfo: "מידע מעקב",
    noTrackingInfo: "אין מידע מעקב זמין.",
    currentStatus: "סטטוס נוכחי:",
    lastUpdated: "עודכן לאחרונה:",
    estimatedDelivery: "מועד משלוח משוער:",
    estimatedDeliveryMessage: "החבילה שלך צפויה להגיע עד {date} (4-6 ימי עסקים ממועד המשלוח).",
    availableForPickup: "זמין לאיסוף",
    availableForPickupMessage: "החבילה שלך זמינה לאיסוף. אנא אסוף אותה מהמיקום המיועד.",
    customsIssue: "בעיה במכס",
    customsIssueMessage: "החבילה שלך מעוכבת במכס. אנא צור קשר עם התמיכה לסיוע נוסף.",
    deliveryIssue: "בעיה במשלוח",
    deliveryIssueMessage: "הייתה בעיה במשלוח. אנא צור קשר עם שירות הלקוחות לסיוע.",
    unknownStatus: "סטטוס לא ידוע",
    unknownStatusMessage: "איננו יכולים לקבוע את הסטטוס הנוכחי. אנא צור קשר עם שירות הלקוחות למידע נוסף.",
    of: "מתוך",
    itemsShipped: "פריטים נשלחו",
    items: { title: "פריטים" },
    order: { title: "לְהַזמִין" },
    orderStatus: {
      fulfilled: "בוצע",
      partiallyFulfilled: "בוצע חלקית",
    },
    trackingNumber: "מספר מעקב:",
    trackingStages: {
      processing: "בעיבוד",
      packaging: "באריזה",
      inTransit: "במעבר",
      outForDelivery: "במשלוח",
      delivered: "נמסר",
    },
    deliveredMessage: "ההזמנה שלך נמסרה.",
    expectedArrivalMessage: "ההזמנה שלך צפויה להגיע עד",
    estimatedDeliveryMessageWithDate: "החבילה שלך צפויה להגיע עד {date} (4-6 ימי עסקים ממועד המשלוח).",
    orderDontexist: "הסדר לא קיים.",
    orderId: "מזהה הזמנה",
    trackingId: "מזהה מעקב",
    backToStore: "חזרה לחנות",
  },
};
