import React, { useState } from "react";

const Dropdown = ({ selectedOption, setSelectedOption, t }) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = [t.orderId, t.trackingId];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative sm:max-w-36 max-w-32 w-full">
      <button
        onClick={toggleDropdown}
        className="w-full px-4 py-2 sm:text-base text-xs text-left bg-white rounded-[19px] focus:outline-none"
        type="button"
      >
        <span>{selectedOption}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute right-4 top-0 bottom-0 my-auto h-5 w-5 text-gray-400 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white rounded-2xl shadow-lg overflow-hidden sm:text-base text-xs">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionClick(option)}
              className={`px-4 py-2 cursor-pointer hover:bg-[#C5856B]/[0.1] transition-colors duration-150 ${
                selectedOption === option ? "bg-[#C5856B]/[0.1]" : ""
              }`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
